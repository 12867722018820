import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core'

const Departament = props => {

  const useStyles = makeStyles({
    li: {
      marginBottom: '15px',
      marginLeft: '10px'
    },
  })

  const classes = useStyles()

  return (
    <li className={classes.li}><Link to={`/categorias/${props.slug}`}>{props.title}</Link></li>
  )
}

export default Departament