import React from 'react'
import { graphql } from 'gatsby'
import { Grid, Container, makeStyles } from '@material-ui/core'
// import Pager from '../components/pagerDepartament'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Departaments from '../components/ListView/Departaments/Departaments'
// import Departaments from '../components/Departaments';

export default ({ data }) => {

  const useStyles = makeStyles(theme => ({
    content: {
      padding: theme.spacing(0, 3, 6),
    },
  }));

  const classes = useStyles();

  const departament = data.departament
  const categories = data.departament.categories
  let empty = ''
  if (categories.length === 0) {
    empty = <div>Nenhum produto foi encontrado.</div>
  }

  return (
    <Master title={departament.title}>
      <GatsbySeo
        title={departament.title}
        description={`Veja mais ${departament.title} em ${process.env.SITE_NAME}`}
        canonical={`${process.env.SITE_URL}/departamentos/${departament.slug}`}
        noindex={categories.length === 0 ? true : false}
        nofollow={categories.length === 0 ? true : false}
        openGraph={{
          url: `${process.env.SITE_URL}/departamentos/${departament.slug}`,
          title: departament.title,
          description: `Veja mais ${departament.title} em ${process.env.SITE_NAME}`,
          images: [
            {
              url: `${process.env.SITE_IMAGE_OG}`,
              width: 512,
              height: 512,
              alt: departament.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Container maxWidth="md" component="main" className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h1>{departament.title}</h1>
            {/* <Departaments align="left" /> */}
            {/* <Pager pageContext={pageContext} slug={departament.slug} /> */}
            <Departaments departaments={categories} />
            {/* <Pager pageContext={pageContext} slug={departament.slug} /> */}
            {empty}
          </Grid>
        </Grid>
      </Container>
    </Master>
  )
}

export const query = graphql`
  query($slug: String!) {
    departament(slug: {eq: $slug}) {
      title
      slug
      categories {
        id
        title
        slug
      }
    }
  }
`

