import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'

import Departament from './Departament/Departament'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const Departaments = props => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <ul>
          {props.departaments.map(departament => (
            <Grid key={departament.id} item xs={12}>
              <Departament
                title={departament.title}
                slug={departament.slug}
              />
            </Grid>
          ))}
        </ul>
      </Grid>
    </div>
  )
}

export default Departaments